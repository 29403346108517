import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import { TrendButton } from '@jetshop/ui/Button';
import { css, cx } from 'linaria';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { SmallCaps } from '../../ui/Headings';
import { activeSegment } from '../UI/Form';

const sectionStyle = css`
   margin-top: 2em;
`;

export default function LoginFields(props) {
   const { fields } = useLoginFields();
   const { globalError, isSubmitting } = useSignupForm();

   function focusSection() {
      if (!props.isActive) props.setActiveSection('loginFields');
   }

   return (
      <section
         className={cx(
            'section',
            props.isActive ? 'active' : null,
            sectionStyle,
            activeSegment,
         )}
      >
         <SmallCaps className="heading">{t('Email & Password')}</SmallCaps>

         {globalError && (
            <GlobalError style={{ marginBottom: '2em' }}>
               {globalError}
            </GlobalError>
         )}

         {fields.map(field => {
            return <Input {...field.inputProps} onFocus={focusSection} />;
         })}

         <TrendButton
            data-testid="sign-up-button"
            type="submit"
            disabled={isSubmitting}
         >
            {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
         </TrendButton>
      </section>
   );
}
