import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import {
   InputError,
   InputPositive,
   ToggleViewPasswordButton,
} from './InputStatus';

export const Input = styled('input')`
   border: 1px solid var(--gray-2);
   font-weight: 400;
   font-size: var(--text-size);
   font-size: 16px;

   padding: 1rem;
   width: 100%;
   padding-right: 40px;

   &.disabled {
      background: #ffffff;
      color: #808080;
   }
`;

export const Wrapper = styled('div')`
   margin-bottom: 1.5rem;
`;

export const ErrorSpan = styled('span')`
   font-style: italic;
   font-size: var(--text-size-small);
   color: var(--red);
   position: absolute;
`;

export const Label = styled('label')`
   display: block;
   font-size: var(--text-size-small);
   margin-bottom: 0.25rem;
   display: block;
   .req {
      color: #707070;
      margin-left: 0.5em;
   }
   &.disabled {
      color: #808080;
   }
`;

const InputWithLabel = ({
   label,
   disabled,
   error,
   success,
   warning,
   loading,
   name,
   required,
   type,
   disableValidation,
   ...props
}) => {
   const [currentType, setType] = useState(type);
   return (
      <Wrapper className={props.wrapperClassName}>
         <Label className={cx(disabled && 'disabled')} htmlFor={name}>
            <span>{label || name}</span>
            {required && <span className="req">{t('(Required)')}</span>}
         </Label>
         <Field
            className={cx(disabled && 'disabled')}
            id={name}
            name={name}
            render={({ field, form: { touched, errors } }) => (
               <div>
                  <Input
                     {...props}
                     {...field}
                     id={name}
                     disabled={disabled}
                     type={currentType}
                  />
                  {type === 'password' && (
                     <ToggleViewPasswordButton
                        className={
                           currentType === 'password' ? 'hidden' : 'visible'
                        }
                        onClick={() => {
                           currentType === 'password'
                              ? setType('text')
                              : setType('password');
                        }}
                     />
                  )}
                  {!disableValidation && (
                     <>
                        {touched?.field?.name && errors?.field?.name && (
                           <InputError />
                        )}
                        {touched?.field?.name && !errors?.field?.name && (
                           <InputPositive />
                        )}
                     </>
                  )}
               </div>
            )}
         />
         {!disableValidation && (
            <ErrorMessage name={name} component={ErrorSpan} />
         )}
      </Wrapper>
   );
};

export default InputWithLabel;
